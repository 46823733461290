import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="voucher-resource"
export default class extends Controller {
  static values = {view: String, projectId: String};
  static targets = ['projectIdSelectInput', 'entitlementSelectInput'];

  get projectIdFromSelect() {
    return this.hasProjectIdSelectInputTarget && this.projectIdSelectInputTarget.value;
  }

  initialize() {
    if (this.viewValue === "new" && this.hasProjectIdSelectInputTarget) {
      this.entitlements = {};
      this.extractEntitlementsFromOptions();
      this.projectIdValue = this.projectIdFromSelect;
    }
  }

  extractEntitlementsFromOptions() {
    // convert <option value="1">Entitlement 1 [proj1]</option> <option value="2">Entitlement 2 [proj1]</option>
    // to entitlements = {proj1: [{value: 1, text: 'Entitlement 1'}, {value: 2, text: 'Entitlement 2'}]
    for (const option of this.entitlementSelectInputTarget.options) {
      if (option.value) {
        const entitlement = this.extractEntitlementFromOption(option);
        if (entitlement) {
          const projectId = entitlement.projectId;
          if (!this.entitlements[projectId]) {
            this.entitlements[projectId] = [];
          }
          this.entitlements[projectId].push(entitlement);
        }
      }
    }
    ;
    this.removeEntitlementSelectOptions()
  }

  showEntitlementSelectOptionsForProject(projectId) {
    const entitlements = this.entitlements[projectId];
    if (entitlements) {
      this.removeEntitlementSelectOptions();
      for (const entitlement of entitlements) {
        const option = this.createOption(entitlement.value, entitlement.text);
        this.entitlementSelectInputTarget.appendChild(option);
      }
    }
  }

  projectIdValueChanged() {
    if (this.viewValue === "new" && this.projectIdValue) {
      this.showEntitlementSelectOptionsForProject(this.projectIdValue);
    }
  }

  onProjectChange(event) {
    if (this.projectIdFromSelect) {
      this.projectIdValue = this.projectIdSelectInputTarget.value;
    }
  }

  removeEntitlementSelectOptions() {
    this.entitlementSelectInputTarget.innerHTML = '';
  }

  extractEntitlementFromOption(option) {
    const match = option.text.match(/\[(.*?)\]$/);
    const entitlement = {};
    if (match) {
      const projectId = match[1];
      entitlement.value = option.value;
      entitlement.text = option.text.replace(/\[.*?\]$/, '');
      entitlement.projectId = projectId;
      return entitlement;
    } else {
      return null;
    }
  }

  createOption(value, text) {
    const option = document.createElement('option');
    option.value = value;
    option.text = text;
    if (text.startsWith('All PRO features')) {
      option.selected = true;
    }
    return option;
  }
}
